export default {
  computed: {
    isTabletMax() {
      return this.$mq === 'smMax' || this.$mq === 'mdMax'
    },
    isLgMax() {
      return this.isTabletMax || this.$mq === 'lgMax'
    },
    isLg() {
      return this.$mq === 'lgMax' || this.$mq === 'xl'
    }
  }
}
