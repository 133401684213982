<template>
  <li class="vh-menu-dropdown-item">
    <button
      class="vh-menu-dropdown-item__button"
      @click="openDropdownHandler"
    >
      <span class="vh-menu-dropdown-item__outline">
        {{ navigation.title }}
      </span>

      <AnimatedChevron
        :is-active="isOpened"
        class="vh-menu-dropdown-item__icon"
      />
    </button>

    <div
      ref="dropdown"
      class="vh-menu-dropdown-item__wrapper"
    >
      <ul class="vh-menu-dropdown-item__list">
        <li
          v-for="(dropdown, i) in navigation.dropdown"
          :key="i"
          class="vh-menu-dropdown-item__inner"
        >
          <a
            :href="dropdown.href"
            :tabindex="isOpened ? 0 : -1"
            target="_blank"
            rel="noopener"
            class="vh-menu-dropdown-item__link"
            @click="clickInnerLinkHandler(dropdown)"
          >
            <span class="vh-menu-dropdown-item__outline">
              {{ dropdown.title }}
            </span>
          </a>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
import AnimatedChevron from '../../UI/components/Buttons/AnimatedChevron'

export default {
  name: 'MenuDropdownItem',
  components: {
    AnimatedChevron
  },
  props: {
    navigation: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isOpened: false
    }
  },
  watch: {
    $mq() {
      if (this.isOpened) {
        const dropdown = this.$refs.dropdown
        const height = dropdown && dropdown.firstElementChild.clientHeight

        if (height) {
          dropdown.style.height = `${height}px`
        }
      }
    }
  },
  methods: {
    clickInnerLinkHandler(dropdownItem) {
      this.$analytics && this.$analytics.push('header-second-level-click', {
        buttonName: this.navigation.customAnalyticButtonName,
        dropdownItemName: dropdownItem.customAnalyticButtonName
      })
    },
    openDropdownHandler({ isAutoOpen = false }) {
      const dropdown = this.$refs.dropdown
      const height = dropdown.firstElementChild.clientHeight

      this.isOpened = !this.isOpened

      dropdown.style.height = this.isOpened ? `${height}px` : '0'

      if (!isAutoOpen && this.isOpened) {
        this.$analytics && this.$analytics.push('header-first-level-click', {
          buttonName: this.navigation.customAnalyticButtonName
        })

        // TODO устаревшее, Анна Ганева обещала посмотреть и выпилить по возможности
        this.navigation.datalayerEvent && window.dataLayer && window.dataLayer.push({ event: this.navigation.datalayerEvent })
      }
    }
  }
}
</script>

<style lang="scss">
  #vh-header.vh-header .vh-menu-dropdown-item__link:not(.vh-menu-dropdown-item__link--active):focus-visible .vh-menu-dropdown-item__outline {
    @include media(lg) {
      &::before {
        background-color: var(--bg-secondary-color);
      }
    }
  }

  #vh-header.vh-header .vh-menu-dropdown-item__button:focus-visible .vh-menu-dropdown-item__outline,
  #vh-header.vh-header .vh-menu-dropdown-item__link:not(.vh-menu-dropdown-item__link--active):focus-visible .vh-menu-dropdown-item__outline {
    &::before {
      background-color: var(--bg-secondary-color);
    }
  }

  #vh-header.vh-header {
    .vh-menu-dropdown-item__button {
      @include inpres;

      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 20px;
      line-height: 1;
      text-align: left;
      cursor: pointer;

      @include media(sm-max) {
        padding-top: 2px;
        padding-bottom: 2px;
      }

      @include media(md) {
        font-size: 24px;
      }
    }

    .vh-menu-dropdown-item__icon {
      margin-right: 2px;
      margin-left: 10px;
    }

    .vh-menu-dropdown-item__wrapper {
      height: 0;
      margin-right: -12px;
      margin-left: -12px;
      padding-right: 12px;
      padding-left: 12px;
      overflow: hidden;
      transition: height .25s ease-in-out;
      will-change: height;

      @include media(md) {
        margin-right: -16px;
        margin-left: -16px;
        padding-right: 16px;
        padding-left: 16px;
      }
    }

    .vh-menu-dropdown-item__list {
      @include ulres;

      padding-top: 8px;
    }

    .vh-menu-dropdown-item__inner {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    .vh-menu-dropdown-item__link {
      position: relative;
      display: block;
      color: inherit;
      font-size: 16px;
      line-height: 1;

      @include media(md) {
        font-size: 20px;
      }
    }

    .vh-menu-dropdown-item__outline {
      position: relative;
      display: inline-block;
      padding-top: 12px;
      padding-bottom: 12px;

      @include media(md) {
        padding-top: 14px;
        padding-bottom: 14px;
      }

      &::before {
        @include z-index(below);

        position: absolute;
        top: 0;
        right: -12px;
        bottom: 0;
        left: -12px;
        border-radius: 200px;
        background-color: transparent;
        content: '';

        @include media(md) {
          right: -16px;
          left: -16px;
        }
      }
    }

    .vh-menu-dropdown-item__link.vh-menu-dropdown-item__link--active .vh-menu-dropdown-item__outline {
      &::before {
        background-color: var(--bg-secondary-accent-color);
      }
    }

    .vh-menu-dropdown-item__button:hover .vh-menu-dropdown-item__outline,
    .vh-menu-dropdown-item__link:not(.vh-menu-dropdown-item__link--active):hover .vh-menu-dropdown-item__outline {
      @include media(lg) {
        &::before {
          background-color: var(--bg-secondary-color);
        }
      }
    }
  }

  .is-safari #vh-header.vh-header .vh-menu-dropdown-item__button:focus .vh-menu-dropdown-item__outline,
  .is-safari #vh-header.vh-header .vh-menu-dropdown-item__link:not(.vh-menu-dropdown-item__link--active):focus .vh-menu-dropdown-item__outline {
    &::before {
      background-color: var(--bg-secondary-color);
    }
  }
</style>
