<!--TODO тут отличие в слоте-->
<template>
  <a
    v-if="href"
    :class="buttonClassList"
    :aria-label="ariaLabel"
    :href="href"
  >
    <svg-icon
      v-if="icon"
      :class="iconClassList"
      :name="icon"
    />

    <slot />
  </a>

  <nuxt-link
    v-else-if="to"
    :class="buttonClassList"
    :aria-label="ariaLabel"
    :to="to"
  >
    <svg-icon
      v-if="icon"
      :name="icon"
      :class="iconClassList"
    />

    <slot />
  </nuxt-link>

  <span
    v-else-if="notInteractive"
    :class="buttonClassList"
    :aria-label="ariaLabel"
  >
    <svg-icon
      v-if="icon"
      :name="icon"
      :class="iconClassList"
    />

    <slot />
  </span>

  <button
    v-else
    :class="buttonClassList"
    :type="type"
    :disabled="isLoading"
    :aria-label="ariaLabel"
    v-on="$listeners"
  >
    <svg-icon
      v-if="icon"
      :name="icon"
      :class="iconClassList"
      width="20"
      height="20"
      aria-hidden="true"
    />

    <slot />

    <UiLoaderAnimation
      v-if="isLoading"
      :class="`ui-icon-button__loader ui-icon-button__loader--${size}`"
    />
  </button>
</template>

<script>
import UiLoaderAnimation from '@skillbox/front-box/dist/Loaders/UiLoaderAnimation/UiLoaderAnimation'

export default {
  name: 'UiIconButton',
  components: {
    UiLoaderAnimation
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    },
    to: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    theme: {
      type: String,
      default: 'filled-main'
    },
    size: {
      type: String,
      default: 'small'
    },
    sizeMode: {
      type: String,
      default: null
    },
    shape: {
      type: String,
      default: 'circle'
    },
    icon: {
      type: String,
      default: null
    },
    ariaLabel: {
      type: String,
      required: true
    },
    notInteractive: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonClassList() {
      return [
        'ui-icon-button',
        `ui-icon-button--${this.theme}`,
        `ui-icon-button--${this.size}`,
        `ui-icon-button--${this.shape}`,
        {
          [`ui-icon-button--${this.sizeMode}`]: this.sizeMode,
          'ui-icon-button--loading': this.isLoading
        }
      ]
    },
    iconClassList() {
      return [
        'ui-icon-button__icon',
        `ui-icon-button__icon--${this.size}`
      ]
    }
  }
}
</script>

<style src="@skillbox/front-box/dist/Buttons/UiIconButton/style.scss" lang="scss" />
