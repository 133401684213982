<template>
  <!-- Отличается логика состояния актиности у разделов 'О скиллбокс' и остальных -->
  <!-- для 'О скиллбокс' активность ставится только если активен какой то из вложенных пунктов -->
  <!-- для остальных активность ставится если мы на любом роуте этого сервиса -->
  <div
    class="vh-menu-desktop-item"
    :class="{'vh-menu-desktop-item--dropdown': item.dropdown}"
    @mouseenter="dropdownToggleByMouse(true)"
    @mouseleave="dropdownToggleByMouse(false)"
  >
    <button
      v-if="item.dropdown"
      class="vh-menu-desktop-item__link vh-menu-desktop-item__link--button"
      @click="dropdownToggleByKeyboard"
    >
      {{ item.title }}

      <svg
        width="12"
        height="12"
        aria-label="Открыть меню"
        class="vh-menu-desktop-item__icon"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2 4L6 8L10 4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
      </svg>

    </button>

    <template v-else>
      <a
        :href="item.href"
        target="_blank"
        class="vh-menu-desktop-item__link"
        :rel="isNofollow ? 'nofollow' : null"
        @click="firsLevelAnalytics"
      >
        {{ item.title }}
      </a>
    </template>

    <transition name="slide-fade">
      <DropdownDesktop
        v-if="item.dropdown"
        v-show="isOpened"
        :dropdown="item.dropdown"
        :is-route="false"
        class="vh-menu-desktop-item__dropdown"
        @link-click="innerLinkClickHandler"
      />
    </transition>
  </div>
</template>

<script>
import DropdownDesktop from '@/components/DropdownDesktop'
import { activateFocusTrap, deactivateFocusTrap } from '@skillbox/front-box/dist/utils/focus-tab'

export default {
  name: 'MenuDesktopItem',
  components: {
    DropdownDesktop
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isNofollow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpened: false
    }
  },
  methods: {
    dropdownToggleByMouse(state) {
      this.isOpened = state

      const isNeedAnalyticsPush = this.item.dropdown && state
      isNeedAnalyticsPush && this.firsLevelAnalytics()
    },
    dropdownToggleByKeyboard() {
      this.isOpened = !this.isOpened

      this.isOpened
        ? activateFocusTrap(this.$el)
        : deactivateFocusTrap(this.$el)

      const isNeedAnalyticsPush = this.item.dropdown && this.isOpened
      isNeedAnalyticsPush && this.firsLevelAnalytics()
    },
    innerLinkClickHandler(dropdownItemName) {
      this.isOpened = false
      this.$emit('close-menu')
      deactivateFocusTrap(this.$el)

      this.$analytics && this.$analytics.push('header-second-level-click', {
        buttonName: this.item.customAnalyticButtonName,
        dropdownItemName
      })
    },
    firsLevelAnalytics() {
      this.$analytics && this.$analytics.push('header-first-level-click', {
        buttonName: this.item.customAnalyticButtonName
      })

      // Устаревшее, Анна Ганева обещала посмотреть
      this.item.datalayerEvent && window.dataLayer && window.dataLayer.push({ event: this.item.datalayerEvent })
    }
  }
}
</script>

<style lang="scss">
  #vh-header.vh-header {
    .vh-menu-desktop-item {
      font-size: 16px;
      line-height: 1;

      &:focus {
        outline: none;
      }

      &--dropdown {
        position: relative;
      }
    }

    .is-safari .vh-menu-desktop-item:focus {
      outline: none;
      outline-width: 0 !important;
    }

    /* stylelint-disable */
    .vh-menu-desktop-item__link {
      @include inpres;

      display: flex;
      align-items: center;
      padding: 12px;
      border-radius: 50px;
      color: var(--text-main-color);
      cursor: pointer;
      transition: background-color $duration-hover-link;

      &--button {
        cursor: default;
        pointer-events: none;
      }

      &--active {
        background-color: var(--bg-secondary-accent-color);
      }
    }

    .vh-menu-desktop-item:hover .vh-menu-desktop-item__link:not(.vh-menu-desktop-item__link--active) {
      background-color: var(--bg-secondary-color);
    }
    /* stylelint-enable */

    .vh-menu-desktop-item__dropdown {
      @include z-index(absolute, header, overlay);

      top: calc(100% + 16px);
      left: -12px;
    }

    .vh-menu-desktop-item__link:not(.vh-menu-desktop-item__link--active):focus-visible {
      background-color: var(--bg-secondary-color);
    }

    .vh-menu-desktop-item__icon {
      display: block;
      margin-left: 8px;
      color: var(--icon-main-color);
    }

    .slide-fade-enter-active {
      transition: all .15s ease-in-out;
    }

    .slide-fade-leave-active {
      transition: all .15s ease-in-out;
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
      transform: translateY(-3px);
      opacity: 0;
    }
  }

  .is-safari #vh-header.vh-header .vh-menu-desktop-item__link:not(.vh-menu-desktop-item__link--active):focus {
    background-color: var(--bg-secondary-color);
  }
</style>
