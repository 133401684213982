<template>
  <transition name="vh-menu-mobile">
    <div
      v-bsl="true"
      v-escape="close"
      class="vh-menu-mobile"
      @click="close"
    >
      <div
        class="vh-menu-mobile__wrapper"
        @click.stop
      >
        <UiIconButtonLocal
          size="small"
          theme="filled-secondary"
          type="button"
          aria-label="Закрыть меню"
          class="vh-menu-mobile__close"
          @click="close"
        >
          <!-- TODO на вьюхе хз как это будет-->
          <svg
            width="20"
            height="20"
            viewBox="0 0 16 16"
            fill="none"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m12.243 3.757-8.485 8.486M3.76 3.758l8.485 8.485" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
        </UiIconButtonLocal>

        <div class="vh-menu-mobile__content ui-container">
          <h3 class="vh-menu-mobile__title">
            Меню
          </h3>

          <MenuSearch
            class="vh-menu-mobile__search"
            @search="menuSearchHandler"
          />

          <MenuDirections
            :tabs="directions"
            class="vh-menu-mobile__direction"
            @tab="close"
          />

          <MenuSale
            v-if="banner"
            :sale="banner"
            class="vh-menu-mobile__sale"
          />

          <MenuNav
            :navigation="navigation"
            class="vh-menu-mobile__nav"
            @click-menu="close"
          />

          <div
            v-if="cards.length"
            class="vh-menu-mobile__cards"
          >
            <MenuCard
              v-for="(card, i) in cards"
              :key="i"
              :card="card"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

import { activateFocusTrap, deactivateFocusTrap } from '@skillbox/front-box/dist/utils/focus-tab'
import MenuNav from '@/components/MenuNav'
import MenuDirections from '@/components/MenuDirections'
import MenuCard from '@/components/MenuCard'
import MenuSearch from '@/components/MenuSearch'
import MenuSale from '@/components/MenuSale'
import UiIconButtonLocal from '@/components/UiIconButtonLocal'

export default {
  name: 'MenuBlock',
  components: {
    MenuSale,
    MenuDirections,
    MenuNav,
    MenuCard,
    MenuSearch,
    UiIconButtonLocal
  },
  props: {
    navigation: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      cards: [
        {
          href: 'https://highereducation.skillbox.ru/',
          title: 'Высшее образование',
          image: 'https://248006.selcdn.ru/MainSite/menu/icon-graduate.svg',
          background: '#74f7b9',
          darkMode: false,
          customAnalyticsAction: 'header-higher-education-click'
        },
        {
          href: 'https://skillbox.pro/mba/',
          title: 'MBA «Лидеры изменений»',
          image: 'https://248006.selcdn.ru/MainSite/menu/icon-mba.svg',
          background: '#353535',
          darkMode: true,
          customAnalyticsAction: 'header-mba-click'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getDirections: 'menu/getDirectionsForNav',
      banner: 'menu/getBanner'
    }),
    directions() {
      return this.getDirections()
    }
  },
  beforeDestroy() {
    deactivateFocusTrap(this.$el)
  },
  mounted() {
    this.$nextTick(() => {
      activateFocusTrap(this.$el)
      this.$el.focus()
    })
  },
  methods: {
    close() {
      this.$emit('close-menu')
    },
    menuSearchHandler(value) {
      this.$analytics && this.$analytics.push('header-search-submit', { search: value })
      window.location.href = `https://skillbox.ru/courses/?search=${encodeURIComponent(value)}&sc=courses`
    }
  }
}
</script>

<style lang="scss">
  #vh-header.vh-header {
    .vh-menu-mobile {
      @include z-index(fixed, popup);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      min-width: 320px;
      padding-left: 20px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      background-color: rgba(var(--accent-always-black-color-rgb), .75);
      outline: none;
      animation: .25s ease-in-out forwards menu-show;

      @include media(md) {
        padding-left: 30px;
      }

      @include media(lg) {
        animation-duration: .3s;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &-leave-to {
        background-color: rgba(var(--accent-always-black-color-rgb), .75);
        animation: .25s ease-in-out .1s forwards menu-hide;
      }
    }

    .vh-menu-mobile__wrapper {
      min-height: 100%;
      border-radius: 20px 0 0 20px;
      background-color: var(--bg-with-shadow-color);
      transform: translateX(100%);
      animation: .25s ease-in-out .1s forwards menu-enter;
    }

    .vh-menu-mobile-leave-to .vh-menu-mobile__wrapper {
      animation: .25s ease-in-out forwards menu-leave;
    }

    .vh-menu-mobile__close {
      position: absolute;
      top: 20px;
      right: 20px;

      @include media(md) {
        top: 32px;
        right: 30px;
      }

      @include media(md) {
        right: 47px;
      }
    }

    .vh-menu-mobile__content {
      padding-top: 48px;
      padding-bottom: 48px;

      @include media(md) {
        padding: 72px 15px 60px;
      }
    }

    .vh-menu-mobile__sale {
      margin-top: 26px;
      margin-bottom: 40px;

      @include media(md) {
        margin-top: 22px;
        margin-bottom: 20px;
      }

      @include media(lg) {
        margin-top: 26px;
        margin-bottom: 32px;
      }
    }

    .vh-menu-mobile__title {
      display: block;
      margin-top: 0;
      margin-bottom: 32px;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;

      @include media(md) {
        margin-bottom: 48px;
        font-size: 52px;
        line-height: 56px;
      }
    }

    .vh-menu-mobile__search {
      margin-bottom: 32px;

      @include media(md) {
        margin-bottom: 48px;
      }
    }

    .vh-menu-mobile__direction {
      margin-bottom: 6px;
    }

    .vh-menu-mobile__cards {
      display: grid;
      grid-gap: 8px;
      margin-top: 7px;

      @include media(md) {
        grid-gap: 16px;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 16px;
      }
    }

    @keyframes menu-enter {
      from { transform: translateX(100%); }
      to { transform: translateX(0); }
    }

    @keyframes menu-leave {
      from { transform: translateX(0); }
      to { transform: translateX(100%); }
    }

    @keyframes menu-show {
      from { background-color: rgba(var(--accent-always-black-color-rgb), 0); }
      to { background-color: rgba(var(--accent-always-black-color-rgb), .75); }
    }

    @keyframes menu-hide {
      from { background-color: rgba(var(--accent-always-black-color-rgb), .75); }
      to { background-color: rgba(var(--accent-always-black-color-rgb), 0); }
    }
  }
</style>
