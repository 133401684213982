import axios from 'axios'

export default {
  namespaced: true,
  state: {
    directions: [],
    allDirectionsItem: {
      slug: 'courses',
      title: 'Все направления'
    },
    banner: null
  },
  mutations: {
    setDirections(state, directions) {
      state.allDirectionsItem.reviews_count = directions.reduce((total, direction) => {
        return total + direction.reviews_count
      }, 0)
      state.directions = directions
    },
    setBanner(state, banner) {
      state.banner = banner
    }
  },
  actions: {
    async fetchDirectionsForMenu({ state, commit }, isNeedCardsInfo = false) {
      if (!state.directions.length) {
        const params = {
          with_nomenclature: isNeedCardsInfo ? 1 : 0 // бэк так хочет ¯\_(ツ)_/¯
        }

        await axios
          .get('https://skillbox.ru/api/v5/ru/sales/skillbox/directions/', {
            params
          })
          .then(({ data: { data: directions } }) => {
            commit('setDirections', directions)
          })
          .catch((e) => {
            console.error(e)
          })
      }
    },
    async fetchBanner({ state, commit }, typeSale = 'skillbox') {
      if (!state.banner) {
        await axios
          .get('https://skillbox.ru/api/v5/ru/sales/main/announcements/menu/', {
            sale_type: typeSale
          })
          .then(({ data: { data: banner } }) => {
            commit('setBanner', banner)
          })
          .catch((e) => {
            console.error(e)
          })
      }
    }
  },
  getters: {
    getDirections: (state) => {
      return state.directions
    },
    getDirectionTitleBySlug: state => (slug) => {
      const direction = state.directions.find(direction => direction.slug === slug)
      return direction ? direction.title : ''
    },
    getIsHideLogin: (state) => {
      return state.isHideLogin
    },
    getDirectionsForNav: state => (slug = 'courses') => {
      const allDirections = Object.assign({}, state.allDirectionsItem, { slug })

      return [allDirections, ...state.directions]
    },
    getBanner: (state) => {
      return state.banner
    }
  }
}
