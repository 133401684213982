<template>
  <svg
    :class="[{'animated-chevron--active': !isActive}, `animated-chevron--${theme}`]"
    :width="theme === 'small' ? 9 : 13"
    :height="theme === 'small' ? 8 : 12"
    class="animated-chevron"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      :class="`animated-chevron__line1--${theme}`"
      class=" animated-chevron__line animated-chevron__line1"
      x1="6.48415"
      y1="5.92242"
      x2="11.4909"
      y2="10.9291"
      stroke="currentColor"
      stroke-width="1.78"
    />

    <line
      class="animated-chevron__line"
      x1="6.3701"
      y1="5.9224"
      x2="11.3768"
      y2="0.915678"
      stroke="currentColor"
      stroke-width="1.78"
    />

    <line
      :class="`animated-chevron__line2--${theme}`"
      class="animated-chevron__line animated-chevron__line2"
      x1="1.36337"
      y1="10.9291"
      x2="6.3701"
      y2="5.9224"
      stroke="currentColor"
      stroke-width="1.78"
    />

    <line
      class="animated-chevron__line"
      x1="1.47743"
      y1="0.915681"
      x2="6.48415"
      y2="5.9224"
      stroke="currentColor"
      stroke-width="1.78"
    />
  </svg>
</template>

<script>
  export default {
    name: 'AnimatedChevron',
    props: {
      isActive: {
        type: Boolean,
        default: false
      },
      theme: {
        type: String,
        default: 'default'
      }
    }
  }
</script>

<style
  lang="scss">
  .animated-chevron {
    transition: stroke-width $duration-long, transform $duration-long;

    &__line1,
    &__line2 {
      transition: stroke-width $duration-long, transform $duration-long;
    }

    &__line {
      stroke-linecap: round;
    }

    &--default &__line {
      stroke-width: 1.78;
    }

    &--small &__line {
      stroke-width: 1.02;
    }

    &--active {
      transform: translateY(25%);

      .animated-chevron__line1 {
        transform: translate(-5.1px, -5.1px);

        &--small {
          transform: translate(-5.1px, -5.1px);
        }
      }

      .animated-chevron__line2 {
        transform: translate(5.1px, -5.1px);

        &--small {
          transform: translate(5.1px, -5.1px);
        }
      }
    }
  }
</style>
