import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueMq from 'vue-mq'
import VBodyScrollLock from 'v-body-scroll-lock'
import customAnalytics from './plugins/customAnalytics.js'
import isSafari from '../UI/plugins/isSafari.js'

import '../UI/assets/scss/fonts.scss'
import './assets/scss/scaffolding.scss'
import '@skillbox/front-box/dist/Functional/UiColors/style.scss'
import '@skillbox/front-box/dist/Typography/UiTypography/style.scss'
import '@skillbox/front-box/dist/Functional/UiScaffolding/style.scss'

Vue.use(customAnalytics)
Vue.use(isSafari)

Vue.use(VBodyScrollLock, {
  bodyScrollOptions: {
    allowTouchMove: el => el.closest(['body-scroll-lock-ignore'])
  }
})

const breakpoints = {
  smMax: 768,
  mdMax: 1024,
  lgMax: 1280,
  xl: Infinity
}

Vue.use(VueMq, {
  defaultBreakpoint: 'default',
  breakpoints
})

Vue.config.productionTip = false

Vue.directive('escape', {
  bind(el, binding, vnode) {
    if (window.screen.width >= breakpoints.mdMax) {
      el.escKeydownHandler = (event) => {
        if (event.key === 'Escape') {
          if (typeof binding.value !== 'function') {
            vnode.context[binding.arg] = binding.value
          } else {
            vnode.context[binding.expression](event, binding.arg)
          }
        }
      }

      document.body.addEventListener('keydown', el.escKeydownHandler)
    }
  },
  unbind(el) {
    document.body.removeEventListener('keydown', el.escKeydownHandler)
    el.escKeydownHandler = null
  }
})

new Vue({
  store,
  render: h => h(App)
}).$mount('#header')
