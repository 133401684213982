var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"vh-ui-search",attrs:{"method":"GET","role":"search"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('label',{staticClass:"vh-ui-search__label"},[_c('button',{staticClass:"vh-ui-search__button vh-ui-search__button--submit",attrs:{"disabled":!_vm.value,"aria-label":"Поиск","type":"submit"}},[_c('svg',{attrs:{"viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg","aria-hidden":"true","width":"16","height":"16"}},[_c('path',{attrs:{"fill":"currentColor","d":"m15.951 15.247-4.373-4.374A5.968 5.968 0 0 0 13 7 6 6 0 0 0 1 7a6 6 0 0 0 6 6 5.967 5.967 0 0 0 3.871-1.42l4.373 4.374.707-.707ZM7 12c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5Z"}})])]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.value),expression:"value",modifiers:{"trim":true}}],class:[
        'vh-ui-search__input',
        ("vh-ui-search__input--" + _vm.size),
        'f f--16',
        ( _obj = {}, _obj[("vh-ui-search__input--" + _vm.sizeMode)] = _vm.sizeMode, _obj )
      ],attrs:{"placeholder":_vm.placeholder,"type":"text","inputmode":"search"},domProps:{"value":(_vm.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.value=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.value)?_c('button',{staticClass:"vh-ui-search__button vh-ui-search__button--clear",attrs:{"aria-label":"Очистить поиск","type":"button"},on:{"click":function($event){_vm.value = ''}}},[_c('svg',{attrs:{"viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg","aria-hidden":"true","width":"16","height":"16"}},[_c('path',{attrs:{"fill":"currentColor","fill-rule":"evenodd","clip-rule":"evenodd","d":"M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Zm11.182 2.475-.706.707L8 8.708l-2.476 2.475-.707-.707 2.476-2.475-2.476-2.475.707-.707L8 7.294l2.476-2.476.706.708L8.707 8l2.475 2.475Z"}})])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }