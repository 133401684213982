<template>
  <ul class="vh-dropdown-desktop">
    <li
      v-for="(link, j) in dropdown"
      :key="j"
      class="vh-dropdown-desktop__item"
    >
      <a
        :href="link.href"
        target="_blank"
        class="vh-dropdown-desktop__link"
        @click="linkClickHandler(link.customAnalyticButtonName)"
      >
        {{ link.title }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DropdownDesktop',
  props: {
    dropdown: {
      type: Array,
      required: true
    },
    isRoute: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    linkClickHandler(dropdownItemName) {
      this.$emit('link-click', dropdownItemName)
    }
  }
}
</script>

<style lang="scss">
  #vh-header.vh-header {
    .vh-dropdown-desktop {
      @include ulres;

      padding: 16px 12px;
      border-radius: 16px;
      background-color: var(--bg-with-shadow-color);
      box-shadow: 0 4px 4px rgba(var(--accent-always-black-color-rgb), .04),
      0 4px 20px rgba(var(--accent-always-black-color-rgb), .1);

      &::before {
        position: absolute;
        top: -16px;
        left: 0;
        display: block;
        width: 100%;
        height: 16px;
        content: '';
      }

      &::after {
        position: absolute;
        top: -6px;
        left: 23px;
        width: 14px;
        height: 14px;
        border-radius: 2px;
        background-color: var(--bg-with-shadow-color);
        transform: rotate(45deg);
        content: '';
      }
    }

    .vh-dropdown-desktop__item {
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    .vh-dropdown-desktop__link:not(.vh-dropdown-desktop__link--active):focus-visible {
      background-color: var(--bg-secondary-color);
    }

    .vh-dropdown-desktop__link {
      display: inline-block;
      width: 100%;
      padding: 10px 12px;
      border-radius: 8px;
      color: var(--text-main-color);
      white-space: nowrap;

      &:not(.vh-dropdown-desktop__link--active):hover {
        background-color: var(--bg-secondary-color);
      }

      &--active {
        background-color: var(--bg-secondary-accent-color);
      }
    }
  }

  .is-safari .vh-dropdown-desktop__link:not(.dropdown-desktop__link--active):focus {
    background-color: var(--bg-secondary-color);
  }
</style>
