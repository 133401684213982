<template>
  <button
    :class="[`vh-toggle-menu--${theme}`, {'vh-toggle-menu--active': isActive}]"
    class="vh-toggle-menu"
    v-on="$listeners"
  >
    <slot />

    <AnimatedChevron
      :is-active="isActive"
      class="vh-toggle-menu__arrow select-arrow select-arrow--small"
    />
  </button>
</template>

<script>
import AnimatedChevron from '../../UI/components/Buttons/AnimatedChevron'

export default {
  name: 'ToggleMenuButton',
  components: {
    AnimatedChevron
  },
  props: {
    theme: {
      type: String,
      default: 'primary'
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
  .is-safari #vh-header.vh-header .vh-toggle-menu:focus {
    border-color: var(--accent-brand-color);
    color: var(--accent-always-white-color);
    background-color: var(--accent-brand-color);
  }

  #vh-header.vh-header {
    /* stylelint-disable */
    .vh-toggle-menu {
      @include inpres(no-margin);

      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--stroke-accent-color);
      border-radius: 100px;
      color: var(--text-main-color);
      font-size: 14px;
      line-height: 1;
      cursor: pointer;
      transition: color $duration-hover-button, background-color $duration-hover-button, border-color $duration-hover-button;

      @include media(md) {
        font-size: 16px;
      }

      @include media(lg) {
        &:hover {
          border-color: var(--accent-brand-color);
          color: var(--accent-always-white-color);
          background-color: var(--accent-brand-color);
        }
      }

      &--active {
        border-color: var(--accent-brand-color);
        color: var(--accent-always-white-color);
        background-color: var(--accent-brand-color);
      }

      &--small {
        padding: 11px;

        @include media(lg) {
          padding-right: 16px;
          padding-left: 16px;
        }

        @include media(lg, lg-max) {
          padding-top: 12px;
          padding-bottom: 12px;
          font-size: 14px;
        }
      }

      &--primary {
        padding: 15px 30px;

        @include media(lg) {
          padding-top: 18px;
          padding-bottom: 18px;
        }

        @include media(xl) {
          padding-right: 40px;
          padding-left: 40px;
        }
      }

      &__arrow {
        margin-top: 1px;
        margin-left: 3px;

        @include media(md) {
          margin-left: 5px;
        }

        @include media(xl) {
          margin-left: 6px;
        }

        @media (max-width: 359.9px) {
          display: none;
        }
      }
    }

    .vh-toggle-menu:focus-visible {
      border-color: var(--accent-brand-color);
      color: var(--accent-always-white-color);
      background-color: var(--accent-brand-color);
    }
    /* stylelint-enable */
  }
</style>
