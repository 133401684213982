<template>
  <button
    :class="{'vh-menu-toggle--opened': isActive}"
    class="vh-menu-toggle"
    type="button"
    aria-label="Открыть меню"
    v-on="$listeners"
  >
    <span class="vh-menu-toggle__line" />
  </button>
</template>

<script>
export default {
  name: 'MenuToggle',
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .is-safari #vh-header.vh-header .vh-menu-toggle:focus .vh-menu-toggle__line {
    background-color: var(--accent-brand-color);

    &::before,
    &::after {
      background-color: var(--accent-brand-color);
    }
  }

  #vh-header.vh-header {
    /* stylelint-disable */
    .vh-menu-toggle {
      @include inpres;

      width: 40px;
      height: 40px;
      margin-right: -8px;
      padding: 7px;
      border: 1px solid transparent;
      border-radius: 50%;
      cursor: pointer;
      transition: border-color $duration $duration-long;
    }

    .vh-menu-toggle__line {
      position: relative;
      display: block;
      width: 100%;
      height: 2px;
      border-radius: 10px;
      background-color: var(--icon-main-color);

      &::before,
      &::after {
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        border-radius: 10px;
        background-color: var(--icon-main-color);
        content: '';
      }

      &::after {
        top: -7px;
      }

      &::before {
        top: 7px;
      }
    }

    .vh-menu-toggle:focus-visible .vh-menu-toggle__line {
      background-color: var(--accent-brand-color);

      &::before,
      &::after {
        background-color: var(--accent-brand-color);
      }
    }
    /* stylelint-enable */
  }
</style>
