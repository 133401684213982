<template>
  <nav
    class="vh-menu-nav"
    @click="menuClickHandler"
  >
    <ul class="vh-menu-nav__list">
      <template v-for="(item, i) in navigation">
        <MenuDropdownItem
          v-if="item.dropdown"
          :key="i"
          :navigation="item"
          class="vh-menu-nav__item"
        />

        <li
          v-else
          :key="i"
          class="vh-menu-nav__item"
        >
          <a
            :href="item.href"
            target="_blank"
            rel="noopener"
            class="vh-menu-nav__link"
            @click="analyticHandler('header-first-level-click', { buttonName: item.customAnalyticButtonName })"
          >
            <span class="vh-menu-nav__outline">
              {{ item.title }}
            </span>
          </a>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
import mq from '../../UI/plugins/mixins/mq'

import MenuDropdownItem from '@/components/MenuDropdownItem'

export default {
  name: 'MenuNav',
  components: {
    MenuDropdownItem
  },
  mixins: [mq],
  props: {
    navigation: {
      type: Array,
      required: true
    },
    isHideLogin: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    menuClickHandler(evt) {
      const target = evt.target

      if (target.closest('.vh-menu-nav__link') || target.closest('.vh-menu-dropdown-item__link')) {
        this.$emit('click-menu')
      }
    },
    analyticHandler(eventName, button) {
      this.$analytics.push(eventName, button)
    }
  }
}
</script>

<style lang="scss">
  #vh-header.vh-header .vh-menu-nav__link:not(.nuxt-link-active):focus-visible .vh-menu-nav__outline {
    &::before {
      background-color: var(--bg-secondary-color);
    }
  }

  #vh-header.vh-header {
    .vh-menu-nav__list {
      @include ulres;
    }

    .vh-menu-nav__item {
      padding-top: 15px;
      padding-bottom: 15px;

      @include media(md) {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--stroke-main-color);
      }
    }

    .vh-menu-nav__link {
      display: block;
      color: inherit;
      font-size: 20px;
      line-height: 1;

      @include media(md) {
        font-size: 24px;
      }

      @include media(lg) {
        transition: color $hover-card;
      }

      &:not(.link-active):active {
        @include media(lg) {
          color: var(--text-main-color)
        }
      }

      &--icon {
        display: flex;
        align-items: center;
      }

      &--login {
        display: none;
      }

      &.link-active {
        cursor: default;
      }
    }

    .vh-menu-nav__icon {
      display: none;
    }

    .vh-menu-nav__outline {
      position: relative;
      display: inline-block;
      padding-top: 14px;
      padding-bottom: 14px;

      &::before {
        @include z-index(below);

        position: absolute;
        top: 0;
        right: -12px;
        bottom: 0;
        left: -12px;
        border-radius: 200px;
        background-color: transparent;
        content: '';

        @include media(md) {
          right: -16px;
          left: -16px;
        }
      }
    }

    .vh-menu-nav__link.nuxt-link-active .vh-menu-nav__outline {
      &::before {
        background-color: var(--bg-secondary-accent-color);
      }
    }

    .vh-menu-nav__link:not(.nuxt-link-active):hover .vh-menu-nav__outline {
      @include media(lg) {
        &::before {
          background-color: var(--bg-secondary-color);
        }
      }
    }
  }

  .is-safari #vh-header.vh-header .vh-menu-nav__link:not(.nuxt-link-active):focus .vh-menu-nav__outline {
    &::before {
      background-color: var(--bg-secondary-color);
    }
  }
</style>
