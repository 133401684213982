<template>
  <article
    :style="styles"
    data-color-theme="light"
    class="vh-menu-sale"
  >
    <p class="vh-menu-sale__text ui-typography-group">
      <a
        :href="sale.url"
        :class="$mq === 'xl' ? 't t--2' : 'f f--16 f--m'"
        class="vh-menu-sale__link"
        @click="saleClickHandler"
        v-html="sale.title_short"
      />

      <span
        v-if="$mq === 'xl'"
        class="vh-menu-sale__desc p p--2"
        v-html="sale.subtitle_short"
      />
    </p>

    <picture class="vh-menu-sale__pic">
    <source
      :srcset="sale.images.menu.webp"
      type="image/webp"
    >

    <img
      :src="sale.images.menu.img"
      :alt="sale.title_short"
      width="198"
      height="139"
      decoding="async"
    >
  </picture>
  </article>
</template>

<script>
  import mq from '../../UI/plugins/mixins/mq'

  export default {
    name: 'MenuSale',
    mixins: [mq],
    props: {
      sale: {
        type: Object,
        required: true
      }
    },
    computed: {
      styles() {
        return {
          '--bg-color': this.sale.background_color,
          '--text-color': this.sale.dark_mode ? '#fff' : '#000'
        }
      }
    },
    methods: {
      saleClickHandler() {
        this.$analytics && this.$analytics.push('header-sale-click')

        // TODO отрефакторить
        window.dataLayer && window.dataLayer.push({ event: 'discountbanner.click', details: { category: 'salebanner.click', action: 'click' } })
        window.dataLayer && window.dataLayer.push({ event: 'main_sale50' })
      }
    }
  }
</script>

<style lang="scss">
  #vh-header.vh-header {
    .vh-menu-sale {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 12px;
      padding-left: 20px;
      border-radius: 12px;
      color: var(--text-color);
      background-color: var(--bg-color);
      transition: opacity $duration-hover-link;

      @include media(md) {
        padding-right: 12px;
      }

      @include media(md) {
        @include z-index(header, raised);
      }

      @include media(xl) {
        grid-gap: 0 20px;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 196px;
        padding: 0 20px 0 32px;
        border-radius: 20px;
      }

      &::before {
        @include media(xl) {
          @include z-index(below);

          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 20px;
          background-color: var(--bg-color);
          transition: all 300ms ease;
          content: '';
        }
      }

      &:hover {
        &::before {
          @include media(xl) {
            transform: scale(1.025);
          }
        }
      }

      &:focus-within {
        &::before {
          @include media(xl) {
            transform: scale(1.025);
          }
        }
      }
    }

    .vh-menu-sale__text {
      margin-bottom: 0;
      padding: 12px 0;

      @include media(md) {
        padding-top: 22px;
        padding-bottom: 22px;
      }

      @include media(xl) {
        align-self: center;
        padding: 32px 0;
      }
    }

    .vh-menu-sale__link {
      display: block;
      color: var(--text-color);

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
      }
    }

    .vh-menu-sale__desc {
      display: block;
      margin-bottom: 0;
    }

    .vh-menu-sale__pic {
      flex-shrink: 0;
      width: 64px;
      height: 64px;

      @include media(lg-max) {
        margin-left: 12px;
      }

      @include media(xl) {
        width: 196px;
        height: 196px;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
