<template>
  <nav class="vh-menu-nav-desktop">
    <ul class="vh-menu-nav-desktop__list">
      <li
        v-for="(item, i) in navigation"
        :key="i"
        class="vh-menu-nav-desktop__item"
      >
        <MenuDesktopItem
          :item="item"
          @close-menu="$emit('close-menu')"
        />
      </li>

      <li
        v-if="!isHideLogin"
        class="vh-menu-nav-desktop__item vh-menu-nav-desktop__item--auth"
      >
        <!-- стоит посмотреть и мб вынести в общий список -->
        <MenuDesktopItem
          :item="{ isRoute: false, href: 'https://go.skillbox.ru/', title: 'Войти', customAnalyticButtonName: 'Authorization', datalayerEvent: 'main_login' }"
          is-nofollow
        />
      </li>
    </ul>
  </nav>
</template>

<script>
import MenuDesktopItem from '@/components/MenuDesktopItem'

export default {
  name: 'MenuNavDesktop',
  components: {
    MenuDesktopItem
  },
  props: {
    navigation: {
      type: Array,
      required: true
    },
    isHideLogin: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
  #vh-header.vh-header {
    .vh-menu-nav-desktop__list {
      @include ulres;

      display: flex;
    }

    .vh-menu-nav-desktop__item {
      &:not(:last-child) {
        margin-right: 12px;
      }

      &--auth {
        margin-left: 32px;
      }
    }
  }
</style>
