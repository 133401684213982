<template>
  <a
    v-if="href"
    :class="classes"
    :href="href"
    class="ui-tab ui-tab--link"
    @click="datalayerEvent"
  >
    <slot />
  </a>

  <nuxt-link
    v-else-if="to"
    :class="classes"
    :to="to"
    class="ui-tab ui-tab--link"
    @click.native="datalayerEvent"
  >
    <slot />
  </nuxt-link>

  <button
    v-else
    :class="classes"
    class="ui-tab ui-tab--special"
    type="button"
    v-on="$listeners"
    @click="datalayerEvent"
  >
    <slot />
    <span v-if="value" class="ui-tab__value">
      {{ value }}
    </span>
  </button>
</template>

<script>
  export default {
    name: 'UiTab',
    props: {
      to: {
        type: [String, Object],
        default: null
      },
      href: {
        type: String,
        default: null
      },
      value: {
        type: Number,
        default: null
      },
      isActive: {
        type: Boolean,
        default: false
      },
      theme: {
        type: String,
        default: 'add'
      },
      align: {
        type: String,
        default: null
      },
      gtmEvent: {
        type: String,
        default: null
      }
    },
    computed: {
      classes() {
        return {
          [`ui-tab--${this.theme}`]: this.theme,
          [`ui-tab--${this.align}`]: this.align,
          'ui-tab--active': this.isActive
        }
      }
    },
    methods: {
      datalayerEvent() {
        this.$emit('click-tab')

        if (this.gtmEvent) {
          const title = this.$slots.default[0].text.trim()
          this.$gtm.push({ event: this.gtmEvent, parametr1: title })
        }
      }
    }
  }
</script>

<style lang="scss">
  .ui-tab {
    @include inpres;

    display: inline-block;
    border: 1px solid var(--stroke-accent-color);
    border-radius: 30px;
    color: var(--text-main-color);
    line-height: 1;
    text-align: center;
    cursor: pointer;
    transition: background-color $duration-hover-button, color $duration-hover-button;

    &:hover {
      @include media(lg) {
        border-color: var(--accent-brand-color);
        color: var(--accent-always-white-color);
        background-color: var(--accent-brand-color);
      }
    }

    &:focus-visible {
      border-color: var(--accent-brand-color);
      color: var(--accent-always-white-color);
      background-color: var(--accent-brand-color);
    }

    &--main {
      border-color: var(--accent-brand-color);
    }

    &--link {
      padding: 18px 24px;
      font-weight: 500;
      font-size: 18px;

      @include media(lg) {
        padding: 18px 32px;
      }
    }

    &--special {
      padding: 11px 16px 11px 20px;
      font-size: 14px;

      @include media(md) {
        font-size: 16px;
      }
    }

    &--small {
      padding: 9px 11px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    // TODO тема из дизайн системы(40px small)
    &--special-for-main-page {
      padding: 11px 12px;
      font-weight: 400;
      font-size: 16px;
    }

    &--grey {
      padding: 8px 12px;
      border: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      &:hover {
        color: var(--text-main-color);
        background-color: var(--bg-secondary-color);
      }

      &:focus-visible {
        color: var(--text-main-color);
        background-color: var(--bg-secondary-color);
      }
    }

    &--active {
      pointer-events: none;
    }

    &--left {
      text-align: left;
    }

    &__value {
      font-size: 8px;
      vertical-align: top;
    }

    &--active,
    &.link-active {
      border-color: var(--accent-brand-color);
      color: var(--accent-always-white-color);
      background-color: var(--accent-brand-color);
      cursor: default;
    }

    &--grey.ui-tab--active,
    &--grey.link-active {
      color: var(--text-main-color);
      background-color: var(--bg-secondary-accent-color);
    }
  }

  .is-safari .ui-tab {
    &:focus {
      border-color: var(--accent-brand-color);
      color: var(--accent-always-white-color);
      background-color: var(--accent-brand-color);
    }

    &--grey {
      &:focus {
        color: var(--text-main-color);
        background-color: var(--bg-secondary-color);
      }
    }
  }
</style>
