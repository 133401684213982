<template>
  <form
    method="GET"
    role="search"
    class="vh-ui-search"
    @submit.prevent="submit"
  >
    <label class="vh-ui-search__label">
      <button
        :disabled="!value"
        aria-label="Поиск"
        class="vh-ui-search__button vh-ui-search__button--submit"
        type="submit"
      >
        <svg
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          width="16"
          height="16"
        >
          <path
            fill="currentColor"
            d="m15.951 15.247-4.373-4.374A5.968 5.968 0 0 0 13 7 6 6 0 0 0 1 7a6 6 0 0 0 6 6 5.967 5.967 0 0 0 3.871-1.42l4.373 4.374.707-.707ZM7 12c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5Z"
          />
        </svg>
      </button>

      <input
        v-model.trim="value"
        :placeholder="placeholder"
        type="text"
        inputmode="search"
        :class="[
          'vh-ui-search__input',
          `vh-ui-search__input--${size}`,
          'f f--16',
          {[`vh-ui-search__input--${sizeMode}`]: sizeMode}
        ]"
      >

      <button
        v-if="value"
        aria-label="Очистить поиск"
        type="button"
        class="vh-ui-search__button vh-ui-search__button--clear"
        @click="value = ''"
      >
        <svg
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          width="16"
          height="16"
        >
          <path
            fill="currentColor"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Zm11.182 2.475-.706.707L8 8.708l-2.476 2.475-.707-.707 2.476-2.475-2.476-2.475.707-.707L8 7.294l2.476-2.476.706.708L8.707 8l2.475 2.475Z"
          />
        </svg>
      </button>
    </label>
  </form>
</template>

<script>
  export default {
    name: 'UiSearch',
    props: {
      placeholder: {
        type: String,
        default: 'Поиск'
      },
      size: {
        type: String,
        default: 'small'
      },
      sizeMode: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        value: ''
      }
    },
    methods: {
      submit() {
        this.$emit('search', this.value)
      }
    }
  }
</script>

<style lang="scss">
  @mixin small {
    height: 40px;
    padding: 10px 48px 10px 40px;
    border-radius: 6px;
  }

  @mixin medium {
    height: 48px;
    padding: 14px 48px 14px 40px;
    border-radius: 8px;
  }

  @mixin big {
    height: 56px;
    padding: 18px 50px 18px 42px;
    border-radius: 10px;
  }

  .vh-ui-search__label {
    position: relative;
    display: block;
  }

  .is-safari .vh-ui-search__input:focus {
    border-color: var(--stroke-secondary-color);
    background-color: var(--bg-with-shadow-color);
    outline: none;

    &::placeholder {
      color: var(--text-disable-color);
    }
  }

  .vh-ui-search__input {
    display: block;
    width: 100%;
    border: 1px solid var(--stroke-main-color);
    color: var(--text-main-color);
    font-family: inherit;
    background-color: var(--bg-secondary-color);
    box-shadow: none;
    transition: background-color $transition-input, border-color $transition-input;
    appearance: none;
    white-space: nowrap;
    text-overflow: ellipsis;

    &::placeholder {
      transition: color $transition-input;
      color: var(--text-secondary-color);
    }

    &:hover {
      @include media(lg) {
        border-color: var(--stroke-secondary-color);
      }
    }

    &:hover::placeholder {
      @include media(lg) {
        color: var(--text-main-color);
      }
    }

    &:focus-visible {
      border-color: var(--stroke-secondary-color);
      background-color: var(--bg-with-shadow-color);
      outline: none;
    }

    &:focus-visible::placeholder {
      color: var(--text-disable-color);
    }

    /* size */
    &--small {
      @include small;
    }

    &--small-md {
      @include media(md) {
        @include small;
      }
    }

    &--small-lg {
      @include media(lg) {
        @include small;
      }
    }

    &--small-xl {
      @include media(xl) {
        @include small;
      }
    }

    &--medium {
      @include medium;
    }

    &--medium-md {
      @include media(md) {
        @include medium;
      }
    }

    &--medium-lg {
      @include media(lg) {
        @include medium;
      }
    }

    &--medium-xl {
      @include media(xl) {
        @include medium;
      }
    }

    &--big {
      @include big;
    }

    &--big-md {
      @include media(md) {
        @include big;
      }
    }

    &--big-lg {
      @include media(lg) {
        @include big;
      }
    }

    &--big-xl {
      @include media(xl) {
        @include big;
      }
    }
    /* end size */
  }

  .vh-ui-search__button {
    @include inpres;

    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    transition: color $duration-hover-button;

    &:disabled {
      cursor: default;
    }

    &--submit {
      left: 0;
      width: 40px;
      padding-left: 10px;
      color: var(--icon-main-color);

      &:focus-visible {
        color: var(--accent-brand-color);
      }
    }

    &--clear {
      right: 0;
      width: 48px;
      color: var(--icon-secondary-color);

      &:hover {
        @include media(lg) {
          color: var(--icon-main-color);
        }
      }

      &:focus-visible {
        color: var(--icon-main-color);
      }
    }
  }

  .is-safari .vh-ui-search__button--clear:focus {
    color: var(--icon-main-color);
  }

  .is-safari .vh-ui-search__button--submit:focus {
    color: var(--accent-brand-color);
  }
</style>
