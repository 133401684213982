<template>
  <ul class="vh-menu-directions">
    <li
      v-for="(tab, index) in tabs"
      :key="index"
      class="vh-menu-directions__item"
    >
      <UiTab
        :href="`https://skillbox.ru/${tab.slug}/`"
        target="_blank"
        :theme="$mq === 'xl' ? 'special-for-main-page' : 'small'"
        class="vh-menu-directions__tab"
        @click.native="handleClick(tab, true)"
      >
        {{ tab.title }}
      </UiTab>
    </li>
  </ul>
</template>

<script>
import UiTab from '../../UI/components/InteractiveElements/UiTab'
export default {
  name: 'MenuDirections',
  components: { UiTab },
  props: {
    tabs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isRouteMode: false
    }
  },
  methods: {
    handleClick({ title, slug }, needEmit) {
      needEmit && this.$emit('tab')

      this.$analytics && this.$analytics.push('header-direction-clicked', { title, slug })

      // TODO, отркфакторить, когда новая аналитика заедет на все проекты
      window.dataLayer && window.dataLayer.push({ event: 'main_popup_click', parametr1: title })
    }
  }
}
</script>

<style lang="scss">
  .is-safari #vh-header.vh-header .vh-menu-directions .vh-menu-directions__tab:focus {
    border-color: var(--accent-brand-color);
    color: var(--accent-always-white-color);
    background-color: var(--accent-brand-color);
  }

  #vh-header.vh-header {
    .vh-menu-directions {
      @include ulres;

      display: flex;
      flex-wrap: wrap;
    }

    .vh-menu-directions__item {
      margin-right: 6px;
      margin-bottom: 6px;

      @include media(md) {
        margin-right: 6px;
        margin-bottom: 10px;
      }

      @include media(xl) {
        margin-right: 4px;
        margin-bottom: 12px;
      }
    }

    .vh-menu-directions .vh-menu-directions__tab {
      @include media(sm-max) {
        padding: 11px;
        font-size: 16px;
      }

      // TODO стили из ui перебиваются
      color: var(--text-main-color);

      @include media(lg) {
        &:hover {
          color: var(--accent-always-white-color);
        }
      }

      @include media(md, lg-max) {
        padding: 12px 16px;
        font-size: 20px;
      }
    }

    // #vh-header.vh-header перебивает ui
    .vh-menu-directions .vh-menu-directions__tab:focus-visible {
      border-color: var(--accent-brand-color);
      color: var(--accent-always-white-color);
      background-color: var(--accent-brand-color);
    }
  }
</style>
