<template>
  <div
    v-bsl="true"
    v-escape="close"
    class="vh-menu-block"
    @click="close"
  >
    <div
      ref="menu"
      class="vh-menu-block__wrapper"
      @click.stop
    >
      <div class="vh-menu-block__content ui-container">
        <UiSearchLocal
          ref="form"
          placeholder="Какой курс вы ищите?"
          size="medium"
          class="vh-menu-block__search"
          @search="menuSearchHandler($event)"
        />

        <MenuSale
          v-if="banner"
          :sale="banner"
          class="vh-menu-block__sale"
        />

        <MenuDirections
          :tabs="directions"
          class="vh-menu-block__direction"
          @tab="close"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { activateFocusTrap, deactivateFocusTrap } from '@skillbox/front-box/dist/utils/focus-tab'
import mq from '../../UI/plugins/mixins/mq'
import MenuSale from '@/components/MenuSale'
import MenuDirections from '@/components/MenuDirections'
import UiSearchLocal from '@/components/UiSearchLocal'

export default {
  name: 'MenuBlock',
  components: {
    MenuDirections,
    MenuSale,
    UiSearchLocal
  },
  mixins: [mq],
  props: {
    navigation: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      getDirections: 'menu/getDirectionsForNav',
      banner: 'menu/getBanner'
    }),
    directions() {
      return this.getDirections()
    }
  },
  beforeDestroy() {
    deactivateFocusTrap(this.$refs.menu)
  },
  mounted() {
    this.$nextTick(() => {
      activateFocusTrap(this.$refs.menu)
      this.$refs.menu.focus()
    })
  },
  methods: {
    menuSearchHandler(value) {
      this.$analytics && this.$analytics.push('header-search-submit', { search: value })
      window.location.href = `https://skillbox.ru/courses/?search=${encodeURIComponent(value)}&sc=courses`
    },
    close() {
      this.$emit('close-menu')
    }
  }
}
</script>

<style lang="scss">
  #vh-header.vh-header {
    .vh-menu-block {
      position: absolute;
      top: 96px;
      right: 0;
      bottom: 0;
      left: 0;
      min-width: 320px;
      min-height: 100vh;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      background-color: rgba(var(--accent-always-black-color-rgb), .75);
      outline: none;
    }

    .vh-menu-block__wrapper {
      background-color: var(--bg-main-color);
      border-radius: 0 0 20px 20px;

      &:focus {
        outline: none;
      }
    }

    .vh-menu-block__content {
      display: grid;
      grid-gap: 40px 130px;
      grid-template-columns: 1fr 470px;
      align-items: flex-start;
      padding-top: 38px;
      padding-bottom: 38px;
      border-top: 1px solid var(--stroke-main-color);
    }

    .vh-menu-block__nav {
      display: none;
    }

    .vh-menu-block__sale {
      grid-row: 1/3;
      grid-column: 2/3;
    }

    .vh-menu-block__search {
      max-width: 368px;
    }
  }
</style>
