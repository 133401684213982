<template>
  <header id="vh-header" class="vh-header" data-color-theme="light">
    <div class="vh-header__wrapper">
      <div class="vh-header__content ui-container">
        <a
          class="vh-header__logo"
          href="https://skillbox.ru"
          target="_blank"
          rel="noopener"
        >
          <img
            :src="logo"
            alt="Skillbox"
            width="84"
            height="19"
          >
        </a>

        <a
          v-if="isLgMax"
          class="vh-header__login"
          href="https://go.skillbox.ru/"
          target="_blank"
          rel="nofollow"
          @click="$analytics && $analytics.push('header-first-level-click', { buttonName: 'Authorization' })"
        >
          Войти
        </a>

        <MenuToggleMobile
          v-if="isLgMax"
          :is-active="isShowMenu"
          @click="buttonClickHandler"
        />

        <MenuToggleDesktop
          v-if="!isLgMax"
          :is-active="isShowMenu"
          theme="small"
          class="vh-header__toggle"
          @click="showMenu"
        >
          Все курсы
        </MenuToggleDesktop>

        <MenuNavDesktop
          v-show="!isLgMax"
          :navigation="navigation"
          :is-hide-login="isHideLogin"
          class="vh-header__nav"
          @close-menu="isShowMenu = false"
        />

        <MenuMobile
          v-if="isLgMax && isShowMenu"
          :navigation="navigation"
          @close-menu="isShowMenu = false"
        />
      </div>
    </div>

    <MenuDropdown
      v-if="!isLgMax && isShowMenu"
      :navigation="navigation"
      @close-menu="isShowMenu = false"
    />
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import mq from '../../UI/plugins/mixins/mq'

import MenuToggleMobile from '@/components/MenuToggleMobile'
import MenuMobile from '@/components/MenuMobile'
import MenuDropdown from '@/components/MenuDropdown'
import MenuNavDesktop from '@/components/MenuNavDesktop'
import MenuToggleDesktop from '@/components/MenuToggleDesktop'

export default {
  name: 'Header',
  components: {
    MenuToggleMobile,
    MenuToggleDesktop,
    MenuDropdown,
    MenuMobile,
    MenuNavDesktop
  },
  mixins: [mq],
  data() {
    return {
      logo: 'https://248006.selcdn.ru/MainSite/skillbox.svg',
      isShowMenu: false,
      navigation: [
        {
          href: 'https://skillbox.ru/company/',
          to: '/company/',
          title: 'О Skillbox',
          isRoute: false,
          defaultActiveState: false,
          datalayerEvent: 'main_company',
          customAnalyticButtonName: 'About',
          dropdown: [
            {
              href: 'https://skillbox.ru/company/',
              to: '/company/',
              title: 'О Платформе',
              customAnalyticButtonName: 'Company'
            },
            {
              href: 'https://skillbox.ru/career/',
              to: '/career/',
              title: 'Центр карьеры',
              customAnalyticButtonName: 'Career'
            },
            {
              href: 'https://skillbox.ru/otzyvy/',
              to: '/otzyvy/',
              title: 'Отзывы',
              customAnalyticButtonName: 'Otzyvy'
            },
            {
              href: 'https://skillbox.ru/contacts/',
              to: '/contacts/',
              title: 'Контакты',
              customAnalyticButtonName: 'Contacts'
            },
            {
              href: 'https://skillbox.ru/jobs/',
              to: '/jobs/',
              title: 'Вакансии',
              customAnalyticButtonName: 'Jobs'
            },
            {
              href: 'https://skillbox.ru/teachers/',
              to: '/teachers/',
              title: 'Школа кураторов',
              customAnalyticButtonName: 'Teachers'
            },
            {
              href: 'https://partners.skillbox.ru/referral',
              to: null,
              title: 'Скидки для друзей',
              customAnalyticButtonName: 'Referral'
            },
            {
              href: 'https://community.skillbox.ru/',
              to: null,
              title: 'Сообщество Skillbox',
              customAnalyticButtonName: 'Community'
            }
          ]
        },
        {
          href: 'https://live.skillbox.ru/',
          to: '/',
          isRoute: false,
          title: 'Вебинары',
          defaultActiveState: true,
          datalayerEvent: 'main_webinars',
          customAnalyticButtonName: 'Webinars',
          dropdown: [
            {
              href: 'https://live.skillbox.ru/',
              to: '/',
              title: 'Все вебинары',
              customAnalyticButtonName: 'All webinars'
            },
            {
              href: 'https://live.skillbox.ru/playlists/',
              to: '/playlists/',
              title: 'Плейлисты',
              customAnalyticButtonName: 'Playlists'
            },
            {
              href: 'https://live.skillbox.ru/calendar/',
              to: '/calendar/',
              title: 'Расписание',
              customAnalyticButtonName: 'Calendar'
            }
          ]
        },
        {
          isRoute: false,
          href: 'https://skillbox.ru/media/',
          to: null,
          title: 'Медиа',
          customAnalyticButtonName: 'Magazine',
          defaultActiveState: true
        },
        {
          isRoute: false,
          href: 'https://b2b.skillbox.ru/',
          to: null,
          title: 'Компаниям',
          customAnalyticButtonName: 'Companies',
          defaultActiveState: true
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isHideLogin: 'menu/getIsHideLogin'
    })
  },
  mounted() {
    this.fetchBanner()
    this.fetchDirectionsForMenu()

    this.$nextTick(() => {
      const el = this.$el.closest('[data-color-theme]')

      if (el) {
        const colorTheme = el.dataset.colorTheme

        if (colorTheme === 'dark') {
          this.logo = 'https://248006.selcdn.ru/MainSite/skillbox-white.svg'
        }
      }
    })
  },
  methods: {
    ...mapActions({
      fetchBanner: 'menu/fetchBanner',
      fetchDirectionsForMenu: 'menu/fetchDirectionsForMenu'
    }),
    buttonClickHandler() {
      !this.isShowMenu && window.scrollBy(0, -window.pageYOffset)
      this.isShowMenu = !this.isShowMenu

      const status = this.isShowMenu ? 'opened' : 'closed'
      this.$analytics && this.$analytics.push('header-toggle-click', { status })
    },
    showMenu() {
      this.isShowMenu = !this.isShowMenu
      this.$analytics && this.$analytics.push('header-all-courses-click')

      // TODO отрефакторить
      window.dataLayer && window.dataLayer.push({ event: 'main_popup_all' })
    }
  }
}
</script>

<style lang="scss">
  #vh-header.vh-header {
    @include z-index(header);
    z-index: 1000; // mobile on tilda TODO

    .vh-header__wrapper {
      position: relative;
      color: var(--text-main-color);
      background-color: var(--bg-main-color);
    }

    .vh-header__content {
      display: flex;
      align-items: center;
      min-height: 74px;

      @include media(md) {
        min-height: 96px;
      }
    }

    .vh-header__logo {
      position: relative;
      width: 84px;
      transition: transform 300ms ease;

      @include media(lg-max) {
        margin-right: auto;
      }

      @include media(md) {
        width: 108px;
      }

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: calc(100% + 24px);
        height: calc(100% + 24px);
        border-radius: 50px;
        background-color: var(--bg-main-color);
        transform: translate(-50%, -50%);
        transition: background-color 300ms ease;
        content: '';
      }

      &:focus-visible::before {
        background-color: var(--bg-secondary-color);
      }

      img {
        position: relative;
        display: block;
        width: 100%;
      }
    }

    /* stylelint-disable */
    .vh-header__login {
      margin-right: 17px;
      color: inherit;
      font-size: 14px;
      line-height: 1;

      @include media(md) {
        margin-right: 13px;
      }

      @include media(lg) {
        margin-right: 13px;
        padding: 12px;
        border-radius: 50px;
        background-color: var(--bg-main-color);
        transition: background-color 300ms ease;
      }

      @include media(xl) {
        display: none;
      }

      &:focus-visible {
        background-color: var(--bg-secondary-color);
      }
    }
    /* stylelint-enable */

    .vh-header__nav {
      @include media(lg-max) {
        display: none;
      }

      @include media(lg) {
        margin-left: auto;
      }
    }

    .vh-header__toggle {
      @include media(lg) {
        margin-right: 20px;
        margin-left: 60px;
      }

      @include media(xl) {
        margin-left: 132px;
      }
    }

    .vh-header .vh-header__toggle {
      @include media(lg-max) {
        display: none;
      }
    }
  }

  .is-safari #vh-header.vh-header .vh-header__logo:focus::before {
    background-color: var(--bg-secondary-color);
  }

  .is-safari #vh-header.vh-header .vh-header__login:focus {
    background-color: var(--bg-secondary-color);
  }
</style>
