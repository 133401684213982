<template>
  <form
    method="GET"
    class="vh-menu-search"
    @submit.prevent="submit(value)"
  >
    <label class="vh-menu-search__label">
      <button
        :disabled="!value"
        aria-label="Поиск"
        class="vh-menu-search__button vh-menu-search__button--submit"
        type="submit"
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9514 12.1257C17.9514 14.7909 15.7909 16.9514 13.1257 16.9514C10.4605 16.9514 8.3 14.7909 8.3 12.1257C8.3 9.46054 10.4605 7.3 13.1257 7.3C15.7909 7.3 17.9514 9.46054 17.9514 12.1257ZM16.9044 16.9474C15.8635 17.7643 14.5515 18.2514 13.1257 18.2514C9.74257 18.2514 7 15.5088 7 12.1257C7 8.74257 9.74257 6 13.1257 6C16.5088 6 19.2514 8.74257 19.2514 12.1257C19.2514 13.5533 18.763 14.8669 17.9443 15.9084L21.9978 19.962L20.9584 21.0014L16.9044 16.9474Z" fill="currentColor"/>
        </svg>
      </button>

      <input
        v-model.trim="value"
        type="text"
        placeholder="Какой курс вы ищете?"
        class="vh-menu-search__input"
      >

      <button
        v-if="value"
        aria-label="Очистить поиск"
        type="button"
        class="vh-menu-search__button vh-menu-search__button--clear"
        @click="value = ''"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          aria-hidden="true"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8C0 3.582 3.582 0 8 0C12.418 0 16 3.582 16 8C16 12.418 12.418 16 8 16C3.582 16 0 12.418 0 8ZM11.1814 10.4749L10.4754 11.1819L7.99942 8.70789L5.52341 11.1829L4.81641 10.4759L7.29242 8.00089L4.81641 5.52588L5.52341 4.81888L7.99942 7.29388L10.4754 4.81787L11.1814 5.52588L8.70642 7.99988L11.1814 10.4749Z" fill="currentColor"/>
        </svg>
      </button>
    </label>
  </form>
</template>

<script>
export default {
  name: 'MenuSearch',
  data() {
    return {
      value: ''
    }
  },
  methods: {
    submit(value) {
      this.$emit('search', value)
    }
  }
}
</script>

<style lang="scss">
  #vh-header.vh-header {
    .vh-menu-search__label {
      position: relative;
      display: block;
    }

    /* stylelint-disable */
    .vh-menu-search__input {
      display: block;
      width: 100%;
      height: 48px;
      padding: 14px 48px 14px 42px;
      border: 1px solid var(--stroke-main-color);
      border-radius: 8px;
      color: var(--text-main-color);
      font-size: 16px;
      font-family: inherit;
      line-height: 20px;
      background-color: var(--bg-secondary-color);
      box-shadow: none;
      transition: background-color $transition-input, border-color $transition-input;
      appearance: none;

      &::placeholder {
        color: var(--text-addition-color);
      }

      &:hover {
        border-color: var(--stroke-secondary-color);
      }

      &:focus-visible {
        border-color: var(--stroke-secondary-color);
        background-color: var(--bg-with-shadow-color);
        outline: none;
      }
    }
    /* stylelint-enable */

    .vh-menu-search__button {
      @include inpres;

      position: absolute;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 100%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: color $duration-hover-button;

      &--submit {
        left: 7px;
      }

      &--clear {
        right: 0;
        width: 48px;
        color: var(--icon-secondary-color);

        &:hover {
          color: var(--icon-main-color);
        }

        &:focus-visible {
          color: var(--icon-main-color);
        }
      }
    }
  }

  .is-safari #vh-header.vh-header .vh-menu-search__input:focus {
    border-color: var(--stroke-secondary-color);
    background-color: var(--bg-with-shadow-color);
    outline: none;
  }

  .is-safari #vh-header.vh-header .vh-menu-search__button--clear:focus {
    color: var(--icon-main-color);
  }
</style>
