<template>
  <article
    :style="{backgroundColor: card.background}"
    :data-color-theme="card.darkMode ? 'dark' : 'light'"
    class="vh-menu-card"
  >
    <a
      :href="card.href"
      target="_blank"
      rel="noopener"
      class="vh-menu-card__link"
      @click="analyticsHandler"
    >
      <h3 class="vh-menu-card__title">
        {{ card.title }}
      </h3>
    </a>

    <img
      :src="card.image"
      class="vh-menu-card__pic"
      alt=""
    >
  </article>
</template>

<script>
export default {
  name: 'MenuCard',
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  methods: {
    analyticsHandler() {
      this.$analytics && this.$analytics.push(this.card.customAnalyticsAction)
    }
  }
}
</script>

<style lang="scss">
  #vh-header.vh-header {
    .vh-menu-card {
      position: relative;
      display: grid;
      grid-gap: 16px;
      grid-template-columns: 1fr 78px;
      align-items: center;
      padding: 12px 16px 12px 24px;
      border-radius: 10px;
      color: var(--text-main-color);
      background-color: var(--bg-secondary-color);
      transition: opacity $duration-hover-link;

      @include media(md, lg-max) {
        grid-gap: 20px;
        grid-template-columns: auto;
        justify-content: center;
        padding: 24px 20px 32px;
      }

      &:focus-within {
        opacity: .9;
      }
    }

    .vh-menu-card__link {
      color: inherit;

      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
      }
    }

    .vh-menu-card__title {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      @include media(md, lg-max) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .vh-menu-card__pic {
      max-width: 100%;
      height: 48px;

      @include media(md, lg-max) {
        grid-row: 1/2;
        justify-self: center;
        width: 96px;
        height: 60px;
      }
    }
  }
</style>
